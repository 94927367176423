<template>
    <LoadedContent class="blog-post" :loaded="isLoaded" :error="isError">
        <h1 >{{ $t('titles.blog_post') }} - {{ page.title }}</h1>

        <PageEditor></PageEditor>
        
        <PageFooter>
            <div class="form-buttons">
                <BaseButton class="btn-settings"  @click="openModal('page_template')" type="button">
                    {{ $t('cms.template') }}
                </BaseButton>
                <BaseButton class="btn-settings"  @click="openModal('page_properties')" type="button">
                    {{ $t('controls.edit_properties') }}
                </BaseButton>
            </div>
            <div class="form-buttons">
                <RouterLinkLocal class="cancel-button" :link="'url.project/:project_code/url.blog_posts'">
                    <BaseButton type="button" class="btn-danger" >
                            {{ $t('controls.cancel') }}
                    </BaseButton>
                </RouterLinkLocal>
                <BaseButton type="button" class="btn-success" @click="handleSubmit">{{ $t('controls.save_changes') }}</BaseButton>
            </div>
        </PageFooter>


        <teleport to="#app">
            <ContentPageForm :baseData="savedPage" :opened="openedModal === 'page_properties'" @close="closeModal()"  :store="'page_editor'" :type="'blog'"  />
            <PageTemplateSelection :page="page"  :opened="openedModal === 'page_template'" @close="closeModal()" />
        </teleport>
    </LoadedContent>
</template>

<script>
import { useStore } from 'vuex';
import LoadedContent from '@common/components/base/LoadedContent';
import PageFooter from '@admin/components/base/PageFooter';
import PageEditor from '@admin/components/cms/content/PageEditor';
import { computed, defineAsyncComponent, ref, watchEffect } from 'vue';
import { useRouter } from 'vue-router';

export default {
    name: "ContentPage",
    components: {
        LoadedContent,
        PageFooter,
        PageEditor,
        ContentPageForm: defineAsyncComponent(() => import(/* webpackChunkName: "content-page-form" */'@admin/components/cms/content/ContentPageForm')),
        PageTemplateSelection: defineAsyncComponent(() => import(/* webpackChunkName: "page-template-selection" */'@admin/components/cms/content/PageTemplateSelection')),
    },
    setup(){
        const store = useStore();

        const router = useRouter();

        const page = computed(() => store.getters['page_editor/getTempPage']);
        const savedPage = computed(() => store.getters['page_editor/getPage']);

        // loading data
        store.dispatch('page_template/fetchItems',{});
        store.dispatch('language/fetchItems',{});
        store.dispatch("content_type/fetchItems",{});

        const loadData = () => {
            if(router.currentRoute.value.name !== 'blog_post_edit'){
                return;
            }
            store.dispatch('page_editor/fetchItem');
        };  
        watchEffect(() => loadData());

        const isLoaded = computed(() => store.getters["page_editor/isLoaded"]);
        
        const isError = computed(() => store.getters["page_editor/isError"]);

        const handleSubmit = () => {
            store.dispatch('page_editor/pageEdit');
        }

        // modal window
        const openedModal = ref(null);
        const openModal = (modal) => {
            openedModal.value = modal;
        }
        const closeModal = () => {
            openedModal.value = null;
        }
        
      
        return {
            isLoaded,
            isError,
            closeModal,
            openedModal,
            openModal,
            handleSubmit,
            page,
            savedPage
        }
    }
}
</script>

<style scoped>
.page-editor{
    overflow: hidden;
}
.page-editor,.loaded-content{
    display: flex;
    flex-direction: column;
    height: 100%;
}

.form-buttons button{
    border-radius: 1.25rem;
    height: 2.5rem;
    margin: 0.3rem 0.5rem;
}
.cancel-button{
    color: white;
}

</style>